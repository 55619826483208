<template>
  <div>
    <v-data-table :loading="loadingTable" disable-pagination disable-sort hide-default-footer :headers="headers"
      :items="categories" sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Relatório de Quartos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-menu :offset-y="true">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn color="primary" outlined icon large v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-icon>
                      {{ icons.mdiTools }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ferramentas</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item @click="sendExportExcel()">
                <v-list-item-title>
                  <v-icon>{{ icons.mdiFileExcelOutline }}</v-icon> Exportar Excel
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a :href="newTab" target="_blank" v-bind="attrs" v-on="on">
                <v-btn class="ml-2" icon large outlined color="primary">
                  <v-icon>{{ icons.mdiOpenInNew }}</v-icon>
                </v-btn>
              </a>
            </template>
            <span>Nova Aba</span>
          </v-tooltip>
          <v-badge :content="bagFilter" :value="bagFilter" bordered color="error" overlap>
            <v-btn @click="dialogFilter = true" class="ml-2" rounded outlined large color="primary">
              <v-icon>{{ icons.mdiFilter }}</v-icon>Filtros
            </v-btn>
          </v-badge>
          <v-spacer></v-spacer>

        </v-toolbar>
      </template>

      <template v-slot:item.valueDiaryToDay="{ item }">
        {{ formatMoney(item.valueDiaryToDay) }}
      </template>
      <template v-slot:item.checkin="{ item }">
        {{ item.current_accommodation ? formatDateTime(item.current_accommodation.date_checkin) : '' }}
      </template>
      <template v-slot:item.prevCheckout="{ item }">
        {{ formatDateTime(item.prevCheckout) }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ status(item.status) }}
      </template>
      <template v-slot:item.company="{ item }">

        {{ company(item) }}
      </template>
      <template v-slot:item.guests="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ item.current_accommodation != null ? item.current_accommodation.guest[0].name : '' }}
            </span>
          </template>
          <span>{{ joinGuest(item) }}</span>
        </v-tooltip>

      </template>
      <template v-slot:foot="{ item }">
        <tr style="height:44px; background:#fafafa">
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL DE QUARTOS:
            <b>{{ contRooms() }} </b></td>
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL HÓSPEDES:<b>
              {{ qtdGuestTotal() }} </b></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
        </tr>

        <tr style="height:44px; background:#fafafa">
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL DIÁRIAS (R$):
            <b>{{ formatMoney(diaryTotal()) }}</b></td>
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">DIÁRIA MEDIA (R$):
            <b>{{ formatMoney(diaryMedia()) }}</b></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
        </tr>
      </template>

      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>
      <template v-slot:loading>
        Carregando...
      </template>
    </v-data-table>

    <!-- DIALOG FILTRO -->
    <v-dialog v-model="dialogFilter" width="700">
      <v-card>
        <BarTitleDialog title="FILTROS" @close="dialogFilter = false" />
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field hide-details v-model="filter.date_start" outlined type="datetime-local"
                label="Data"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select v-model="filter.status" hide-details outlined label="Status" :items="statusItems"></v-select>
            </v-col>
            <!-- <v-col cols="6">
                        <v-text-field hide-details v-model="filter.date_end" outlined type="date" label="Data Final"></v-text-field>
                    </v-col> -->
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-checkbox v-model="filter.period" label="No período"></v-checkbox> </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-left">
              <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
              <v-btn @click="confirmFilter()" color="primary" class="ml-2" x-large>aplicar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
  </div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'

import {
  mdiClose,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiOpenInNew,
  mdiTools,
  mdiFileExcelOutline,
  mdiFilter,
} from '@mdi/js'
import { mapActions } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'

export default {
  components: {
    MsgDelete,
    NoDataTable,
    BarTitleDialog,
  },
  data: () => ({
    dialogFilter: false,
    filter: {},
    categoryItems: [],
    loadingTable: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    dialogDelete: false,
    dialog: false,
    loading: false,
    headers: [
      // {
      //     text: 'ID',
      //     align: 'start',
      //     value: 'id',
      // },
      {
        text: 'QUARTO',
        value: 'description',
      },

      {
        text: 'CATEGORIA',
        value: 'category.description',
      },
      {
        text: 'STATUS',
        value: 'status',
      },
      {
        text: 'CHECKIN',
        value: 'checkin',
      },
      {
        text: 'CHECKOUT',
        value: 'prevCheckout',
      },
      {
        text: 'PAX',
        value: 'pax',
      },
      {
        text: 'HÓSPEDES',
        value: 'guests',
      },
      {
        text: 'EMPRESA',
        value: 'company',
      },
      {
        text: 'DIÁRIA (R$)',
        value: 'valueDiaryToDay',
      },
    ],
    categories: [],
    editedIndex: -1,
    editedItem: {
      description: '',
    },
    defaultItem: {
      description: '',
    },
    options: {},
    statusItems: [
      {
        value: 'sujo',
        text: 'Sujo',
      },
      {
        value: 'disponivel',
        text: 'Disponível',
      },
      {
        value: 'manutencao',
        text: 'Manutenção',
      },
      {
        value: 'ocupado',
        text: 'Ocupado',
      },
    ],

    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
      mdiOpenInNew,
      mdiTools,
      mdiFileExcelOutline,
      mdiFilter,
    },
  }),

  computed: {
    newTab() {
      let url_atual = window.location.href
      url_atual = url_atual.replace('#', '')
      return url_atual + '_blank'
    },
    bagFilter() {
      return Object.keys(this.filter).length
    },
  },
  watch: {},

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('occupied_rooms', ['getAll', 'exportExcel']),
    initialize() {
      this.loadingTable = true

      this.getAll(this.filter)
        .then(response => {
          this.categories = response.data
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    confirmFilter() {
      this.initialize()
      this.dialogFilter = false
    },
    sendExportExcel() {
      this.exportExcel(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => { })
    },
    status(status) {
      switch (status) {
        case 'sujo':
          return 'Sujo'
        case 'bloqueado':
          return 'Bloqueado'
        case 'diaria':
          return 'Diária'
        case 'disponivel':
          return 'Disponível'
        case 'conferencia':
          return 'Conferência'
        case 'encerramento':
          return 'Encerramento'
        case 'limpeza':
          return 'Limpeza'
        case 'manutencao':
          return 'Manutenção'
        case 'ocupado':
          return 'Ocupado'
        case 'periodo':
          return 'Período'
        case 'pernoite':
          return 'Pernoite'
        case 'reservado':
          return 'Reservado'
        case 'stand-by':
          return 'Stand-by'
      }
    },
    clearFilter() {
      this.filter = {}
    },
    contRooms() {
      return this.categories.length
    },
    qtdGuestTotal() {
      return this.categories.reduce((acc, item) => {
        //se exite uma hopedagem currente
        if (item.current_accommodation) {
          return acc + parseFloat(item.current_accommodation.guest.length)
        }
        return acc
      }, 0)
    },
    diaryTotal() {
      return this.categories.reduce((acc, item) => {
        if (item.valueDiaryToDay) {
          return acc + parseFloat(item.valueDiaryToDay)
        }
        return acc
      }, 0)
    },
    diaryMedia() {
      const diaryTotal = this.diaryTotal()

      if (diaryTotal > 0) {
        return diaryTotal / this.contRooms()
      }

      return 0
    },
    joinGuest(room) {
      if (room.current_accommodation) {
        let guests = room.current_accommodation.guest
        let guestsName = []
        guests.forEach(guest => {
          guestsName.push(guest.name)
        })
        return guestsName.join(', ')
      }
    },
    company(item) {
      if (item.current_accommodation) {
        return item.current_accommodation.company != null ? item.current_accommodation.company.fantasy_name : ''
      }
      return ''
    },
    formatDate(date) {
      if (date) {
        const result = new Date(date)
        return result.toLocaleDateString('pt-BR', {
          timeZone: 'America/Sao_Paulo',
        })
      }
    },
    formatDateTime(date) {
      if (date) {
        const result = new Date(date)
        return (
          result.toLocaleDateString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
          }) +
          ' ' +
          result.toLocaleTimeString('pt-BR')
        )
      }
    },
    formatMoney(value) {
      value = parseFloat(value)
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
  },
}
</script>
